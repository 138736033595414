{
  "name": "customer-portal",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=4200",
    "build-dev": "ng build -c=development",
    "build-prod": "ng build -c=production",
    "build-release": "ng build -c=release",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "translate": "concurrently --kill-others \"json-server --watch src/assets/i18n/en.json --port=4000\" \"json-server --watch src/assets/i18n/sv.json --port=4001\" \"json-server --watch src/assets/i18n/no.json  --port=4002\" \"json-server --watch src/assets/i18n/fi.json  --port=4003\" \"json-server --watch src/assets/i18n/da.json  --port=4004\" \"json-server --watch src/assets/i18n/de.json  --port=4005\" "
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@flashphoner/websdk": "^2.0.228",
    "@ng-select/ng-select": "^12.0.5",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@tinymce/tinymce-angular": "^7.0.0",
    "angular-mentions": "^1.5.0",
    "dom-autoscroller": "^2.3.4",
    "ng-recaptcha": "^13.2.1",
    "ng2-dragula": "^5.1.0",
    "ngx-bootstrap": "^12.0.0",
    "ngx-color-picker": "^16.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-infinite-scroll": "^17.0.0",
    "ngx-pagination": "^6.0.3",
    "ngx-toastr": "^19.0.0",
    "rxjs": "^7.8.0",
    "signature_pad": "^4.1.7",
    "tinymce": "^5.10.7",
    "tslib": "^2.4.1",
    "unidecode": "^0.1.8",
    "zone.js": "^0.14.8"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "^17.5.1",
    "@angular-eslint/eslint-plugin": "^17.5.1",
    "@angular-eslint/eslint-plugin-template": "^17.5.1",
    "@angular-eslint/schematics": "^17.5.1",
    "@angular-eslint/template-parser": "^17.5.1",
    "@angular/cli": "17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@angular/language-service": "^17.3.12",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^20.14.11",
    "@typescript-eslint/eslint-plugin": "^7.16.1",
    "@typescript-eslint/parser": "^7.16.1",
    "eslint": "^8.57.0",
    "jasmine-core": "^5.1.2",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "prettier": "^3.3.3",
    "ts-node": "^10.9.2",
    "typescript": "^5.4.5"
  }
}
